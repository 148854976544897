import "@github/file-attachment-element";
import type FileAttachmentElement from "@github/file-attachment-element";
import { type Attachment } from "@github/file-attachment-element";

declare global {
  interface ElementEventMap {
    "file-attachment-accepted": CustomEvent;
  }
}

const handleDragAndDropEvent = (event: CustomEvent): void => {
  if (event.target === null) return;
  const target = event.target as FileAttachmentElement;
  const pre = target.querySelector("pre");

  // Show file names inside pre tag in the dropzone
  if (pre === null) return;
  const { attachments } = event.detail;
  pre.textContent = attachments.map((a: Attachment) => a.file.name).join("\n");

  const fieldId = target.getAttribute("input");
  if (fieldId === null) return;

  const fileInputField = document.getElementById(fieldId) as HTMLInputElement;
  if (fileInputField === null) return;

  // Attachments are added by the file-attachment package asynchronously,
  // so we need to wait for that to complete. The code below does not handle
  // that properly, but still seems to ensure that we loose the race condition,
  // meaning that the files are there when we add them to the input field.
  const dataTransfer = new DataTransfer();
  Promise.all(
    attachments.map(async (a: Attachment) => {
      await new Promise<void>((resolve) => {
        // Add file to dataTransfer and resolve the promise
        dataTransfer.items.add(a.file);
        resolve();
      });
    }),
  )
    .then(() => {
      // All files have been added to dataTransfer
      fileInputField.files = dataTransfer.files;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const initTree = (root: Element): void => {
  root.removeEventListener("file-attachment-accepted", handleDragAndDropEvent);
  root.addEventListener("file-attachment-accepted", handleDragAndDropEvent);
};
