import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";

export default class LogoutController extends Controller {
  static values = { url: String };
  declare readonly urlValue: string;

  logout(): void {
    // End the Intercom session, so that that the logged out lead doesn't see
    // the conversations of the previously logged in user.
    // check if window.Intercom is defined before calling it
    if (typeof window.Intercom === "function") {
      try {
        window.Intercom("shutdown");
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            message: "Intercom error during logout",
          },
        });
      }
    }

    // Navigate to the logout URL.
    window.location.href = this.urlValue;
  }
}
