import { Controller } from "@hotwired/stimulus";

export default class MenuItemController extends Controller {
  static values = { key: String, open: Boolean };
  declare keyValue: string;
  declare openValue: boolean;

  static targets = ["iconOpen", "iconClosed", "expandable"];
  declare readonly iconOpenTarget: HTMLElement;
  declare readonly iconClosedTarget: HTMLElement;
  declare readonly expandableTarget: HTMLElement;

  connect(): void {
    this.loadState();
  }

  toggle(): void {
    this.openValue = !this.openValue;
    this.saveState();
  }

  openValueChanged(): void {
    this.iconClosedTarget.classList.toggle("hidden", this.openValue);
    this.iconOpenTarget.classList.toggle("hidden", !this.openValue);
    this.expandableTarget.classList.toggle("hidden", !this.openValue);
  }

  saveState(): void {
    if (this.keyValue === "") return;
    sessionStorage.setItem(
      `menu-item.${this.keyValue}.open`,
      this.openValue.toString(),
    );
  }

  loadState(): void {
    if (this.keyValue === "") return;
    const value = sessionStorage.getItem(`menu-item.${this.keyValue}.open`);
    if (value === null) return;
    this.openValue = value === "true";
  }
}
