const updateSummary = (formEl: Element): void => {
  const form = formEl as HTMLFormElement;

  let owner = getSelectValue(form, 'select[name="owner"]');
  if (owner !== null) {
    // Extract email address from owner label of form: "Name (email)"
    owner = owner.replace(/^.*\(/, "").replace(/\)$/, "");
  }

  const team = getSelectValue(form, 'select[name="team"]');

  const cardEnabled = getInputValue(form, 'input[name="card_enabled"]:checked');
  const receiptCollectionEnabled = getInputValue(
    form,
    'input[name="receipt_collection_enabled"]:checked',
  );

  const budgetAmount = getInputValue(form, 'input[name="budget_amount_0"]');
  const budgetCurrency = getSelectValue(form, 'select[name="budget_amount_1"]');
  const budgetFrequency = getSelectValue(
    form,
    'select[name="budget_frequency"]',
  );

  updateField("#owner-description", owner);
  updateField("#team-description", team);
  updateField("#card-description", cardEnabled);
  updateField("#receipt-collection-description", receiptCollectionEnabled);

  if (
    budgetAmount !== null &&
    budgetCurrency != null &&
    budgetFrequency != null
  ) {
    const formattedAmount = Intl.NumberFormat().format(Number(budgetAmount));
    updateField(
      "#budget-description",
      `${formattedAmount} ${budgetCurrency} ${budgetFrequency.toLowerCase()}`,
    );
  } else {
    updateField("budget", null);
  }
};

const getSelectValue = (
  form: HTMLFormElement,
  selector: string,
): string | null => {
  const select = form.querySelector(selector) as HTMLSelectElement;
  if (select === null) return null;
  if (select.value === "") return null;
  const selectedOption = select.options[select.selectedIndex];
  return selectedOption.text;
};

const getInputValue = (
  form: HTMLFormElement,
  selector: string,
): string | null => {
  const input = form.querySelector(selector) as HTMLInputElement;
  if (input === null) return null;
  switch (input.value) {
    case "":
      return null;
    case "True":
      return "Yes";
    case "False":
      return "No";
    default:
      return input.value;
  }
};

const updateField = (
  fieldSelector: string,
  description: string | null,
): void => {
  const fieldSpan = document.querySelector(fieldSelector);
  if (fieldSpan === null) return;
  fieldSpan.innerHTML = description ?? "-";
};

const handleListener = (event: Event): void => {
  if (event.target === null) return;
  const form = (event.target as HTMLElement).closest("form");
  if (form === null) return;
  updateSummary(form);
};

export const initTree = (root: Element): void => {
  root.querySelectorAll("#sub-create-form").forEach((formEl) => {
    updateSummary(formEl);
    formEl.removeEventListener("input", handleListener);
    formEl.addEventListener("input", handleListener);
  });
};
