import { Controller } from "@hotwired/stimulus";

export default class AccountingSystemFormController extends Controller {
  static values = { system: { type: String } };
  declare systemValue: string;

  static targets = ["systemSelect", "otherField"];
  declare readonly systemSelectTarget: HTMLSelectElement;
  declare readonly otherFieldTarget: HTMLElement;

  connect(): void {
    // Get initial value from the select element
    this.systemValue = this.systemSelectTarget.value;
  }

  changeSystem(event: Event): void {
    // Update when the select element changes
    this.systemValue = (event.target as HTMLSelectElement).value;
  }

  systemValueChanged(): void {
    this.otherFieldTarget.classList.toggle(
      "hidden",
      this.systemValue !== "OTHER",
    );
  }
}
