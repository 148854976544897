import { Controller } from "@hotwired/stimulus";

export default class FormController extends Controller {
  static targets = ["fileInput"];

  submit(event: Event): void {
    event.preventDefault();
    const form = this.element as HTMLFormElement;

    const fileInputs = Array.from(
      form.querySelectorAll<HTMLInputElement>(
        'input[type="file"][data-auto-open-when-missing-on-submit="true"]',
      ),
    );
    for (const fileInput of fileInputs) {
      if ((fileInput.files?.length ?? 0) === 0) {
        fileInput.click();
        fileInput.addEventListener("change", () => {
          if ((fileInput.files?.length ?? 0) > 0) {
            this.submit(event);
          }
        });
        return;
      }
    }
    form.requestSubmit();
  }
}
