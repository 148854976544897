import { Controller } from "@hotwired/stimulus";

interface PricingDetails {
  free: number;
  starter: number;
  pro: number;
  enterprise: number;
  currencyPrefix: string;
  currencySuffix: string;
}

type Currency = "DKK" | "EUR" | "GBP" | "NOK" | "SEK";

interface Params {
  currency: Currency;
}

export default class PriceTableController extends Controller {
  static values = { currency: String };
  declare currencyValue: Currency;

  static targets = [
    "freePrice",
    "starterPrice",
    "proPrice",
    "enterprisePrice",
    "currencyPrefix",
    "currencySuffix",
    "currencyButton",
  ];

  static prices: Record<Currency, PricingDetails> = {
    DKK: {
      free: 0,
      starter: 650,
      pro: 1290,
      enterprise: 2690,
      currencyPrefix: "",
      currencySuffix: "kr",
    },
    EUR: {
      free: 0,
      starter: 85,
      pro: 175,
      enterprise: 370,
      currencyPrefix: "€",
      currencySuffix: "",
    },
    GBP: {
      free: 0,
      starter: 75,
      pro: 150,
      enterprise: 310,
      currencyPrefix: "£",
      currencySuffix: "",
    },
    NOK: {
      free: 0,
      starter: 990,
      pro: 1990,
      enterprise: 4190,
      currencyPrefix: "",
      currencySuffix: "kr",
    },
    SEK: {
      free: 0,
      starter: 990,
      pro: 1990,
      enterprise: 4190,
      currencyPrefix: "",
      currencySuffix: "kr",
    },
  };

  declare readonly freePriceTarget: HTMLElement;
  declare readonly starterPriceTarget: HTMLElement;
  declare readonly proPriceTarget: HTMLElement;
  declare readonly enterprisePriceTarget: HTMLElement;
  declare readonly currencyButtonTargets: HTMLElement[];

  setCurrency({ params }: { params: Params }): void {
    this.currencyValue = params.currency;
  }

  currencyValueChanged(): void {
    const prices = PriceTableController.prices[this.currencyValue];
    this.freePriceTarget.textContent = `${prices.currencyPrefix} ${prices.free} ${prices.currencySuffix}`;
    this.starterPriceTarget.textContent = `${prices.currencyPrefix} ${prices.starter} ${prices.currencySuffix}`;
    this.proPriceTarget.textContent = `${prices.currencyPrefix} ${prices.pro} ${prices.currencySuffix}`;
    this.enterprisePriceTarget.textContent = `${prices.currencyPrefix} ${prices.enterprise} ${prices.currencySuffix}`;
    this.updateCurrencyButtons();
  }

  updateCurrencyButtons(): void {
    this.currencyButtonTargets.forEach((button) => {
      const currency = button.getAttribute("data-price-table-currency-param");
      if (currency === this.currencyValue) {
        button.classList.add("c-btn-purple");
        button.classList.remove("c-btn-white-purple");
      } else {
        button.classList.remove("c-btn-purple");
        button.classList.add("c-btn-white-purple");
      }
    });
  }
}
