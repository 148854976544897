import { Controller } from "@hotwired/stimulus";

export default class ModalController extends Controller {
  static values = { id: String, open: { type: Boolean, default: false } };
  declare readonly idValue: string;

  declare dialog: HTMLDialogElement;

  connect(): void {
    this.dialog = this.element as HTMLDialogElement;
  }

  open(event?: CustomEvent): void {
    // Ignore the event if is is intended for another modal.
    if (event?.detail?.modalId && event.detail?.modalId !== this.idValue) {
      return;
    }

    this.dialog.showModal();
  }

  close(event?: CustomEvent): void {
    // Ignore the event if is is intended for another modal.
    if (event?.detail?.modalId && event.detail?.modalId !== this.idValue) {
      return;
    }

    this.dialog.close();
  }

  click(event: PointerEvent): void {
    // Close the modal if any click is outside the content area,
    // e.g.on the backdrop.

    const contentRect = this.dialog
      .querySelector(".modal-content")
      ?.getBoundingClientRect();
    if (!contentRect) return;

    if (
      event.clientX < contentRect.left ||
      event.clientX > contentRect.right ||
      event.clientY < contentRect.top ||
      event.clientY > contentRect.bottom
    ) {
      this.close(undefined);
    }
  }
}
