/* eslint-disable @typescript-eslint/no-explicit-any, prefer-rest-params */

declare global {
  interface Window {
    Intercom: ((...args: any[]) => void) | undefined;
    intercomSettings:
      | undefined
      | {
          app_id: string;
        };
  }
}

export const init = (): void => {
  if (typeof window.Intercom === "function") {
    window.Intercom("reattach_activator");
    window.Intercom("update", window.intercomSettings);
    return;
  }

  const i = function (): void {
    i.c(arguments);
  };
  i.q = [] as any;
  i.c = function (args: any): void {
    i.q.push(args);
  };
  window.Intercom = i;

  const scripts = document.getElementsByTagName("script");
  if (scripts.length === 0) {
    console.error(
      "Could not find a script tag to insert the Intercom script after.",
    );
    return;
  }
  const firstScriptEl = scripts[0];
  if (firstScriptEl.parentNode === null) {
    console.error("Could not insert Intercom script: No parent node found.");
    return;
  }
  const parentEl = firstScriptEl.parentNode;

  const l = (): void => {
    if (window.intercomSettings === undefined) {
      // Exit if Intercom is not configured on this page
      return;
    }
    const scriptEl = document.createElement("script");
    scriptEl.type = "text/javascript";
    scriptEl.async = true;
    scriptEl.src = `https://widget.intercom.io/widget/${window.intercomSettings.app_id}`;
    parentEl.insertBefore(scriptEl, firstScriptEl);
  };

  if (document.readyState === "complete") {
    l();
  } else {
    window.addEventListener("load", l, false);
  }
};

export const trackPageView = (): void => {
  if (typeof window.Intercom === "function") {
    window.Intercom("update", window.intercomSettings);
  }
};
