import { Application } from "@hotwired/stimulus";
import AccordionController from "./controllers/accordion";
import AccountingSystemFormController from "./controllers/accounting-system-form";
import AdyenTransferInstrumentsController from "./controllers/adyen-transfer-instruments";
import AdyenRevealController from "./controllers/adyen-reveal";
import CookieSettingsController from "./controllers/cookie-settings";
import DecisionMakers from "./controllers/decision-makers";
import DropdownController from "./controllers/dropdown";
import FormController from "./controllers/form";
import LogoutController from "./controllers/logout";
import MenuItemController from "./controllers/menu-item";
import ModalController from "./controllers/modal";
import ModalToggleController from "./controllers/modal-toggle";
import NavbarController from "./controllers/navbar";
import NavbarToggleController from "./controllers/navbar-toggle";
import SidebarController from "./controllers/sidebar";
import SidebarToggleController from "./controllers/sidebar-toggle";
import SlugifyController from "./controllers/slugify";
import TooltipController from "./controllers/tooltip";
import PriceTableController from "./controllers/price-table";

const Stimulus = Application.start();
Stimulus.register("accordion", AccordionController);
Stimulus.register("accounting-system-form", AccountingSystemFormController);
Stimulus.register(
  "adyen-transfer-instruments",
  AdyenTransferInstrumentsController,
);
Stimulus.register("adyen-reveal", AdyenRevealController);
Stimulus.register("cookie-settings", CookieSettingsController);
Stimulus.register("decision-makers", DecisionMakers);
Stimulus.register("dropdown", DropdownController);
Stimulus.register("form", FormController);
Stimulus.register("logout", LogoutController);
Stimulus.register("menu-item", MenuItemController);
Stimulus.register("modal-toggle", ModalToggleController);
Stimulus.register("modal", ModalController);
Stimulus.register("navbar-toggle", NavbarToggleController);
Stimulus.register("navbar", NavbarController);
Stimulus.register("price-table", PriceTableController);
Stimulus.register("sidebar-toggle", SidebarToggleController);
Stimulus.register("sidebar", SidebarController);
Stimulus.register("slugify", SlugifyController);
Stimulus.register("tooltip", TooltipController);

declare global {
  interface Window {
    Stimulus: Application;
  }
}
window.Stimulus = Stimulus;
