import Plausible from "plausible-tracker";
import { extractEventPropsFromElement } from "./events";

const { enableAutoPageviews, trackEvent } = Plausible({
  // Send the event to the same host as the page.
  apiHost: "",
});

export const init = (): void => {
  // This tracks the current page view and all future ones as well.
  enableAutoPageviews();

  // This tracks all outbound link clicks.
  // enableAutoOutboundTracking();
};

export const initTree = (root: Element): void => {
  // This tracks all clicks on elements with a data-event attribute.
  root.querySelectorAll("[data-event]").forEach((element) => {
    const event = element.getAttribute("data-event");
    if (event === null) return;
    element.addEventListener("click", () => {
      const props = extractEventPropsFromElement(element);
      trackEvent(event, { props });
    });
  });
};
