import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: import.meta.env.PROD
    ? "https://204ecc43defd4da6b37415af1bc09206@o4504528213573632.ingest.sentry.io/4505158965788672"
    : undefined,

  // Vite will replace these values with the actual ones during the build
  environment: import.meta.env.PROD ? "prod" : "local",
  release: import.meta.env.VITE_GIT_SHA,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Capture performance traces for 10% of transactions
  tracesSampleRate: 0.1,

  // Capture replay for 5% of all sessions
  replaysSessionSampleRate: 0.05,
  // Captura replay for all sessions with an error
  replaysOnErrorSampleRate: 1.0,
});
