type EventProps = Readonly<Record<string, string | number | boolean>>;

export const extractEventPropsFromElement = (element: Element): EventProps => {
  return Array.from(element.attributes)
    .map((attr) => [attr.name, attr.value])
    .filter(([name]) => name.startsWith("data-event-prop-"))
    .map(([name, value]) => [name.replace("data-event-prop-", ""), value])
    .reduce<Record<string, string>>((acc, [name, value]) => {
      acc[name] = value;
      return acc;
    }, {});
};
