/* eslint-disable @typescript-eslint/no-explicit-any */

import type { TooltipItem } from "chart.js";

export const initTree = (root: Element): void => {
  // Find all charts.
  const elements = root.querySelectorAll(".chart-data");

  // If there are no charts, exit before loading the chart library.
  if (elements.length === 0) {
    return;
  }

  renderAll(elements).catch((reason) => {
    console.log("Failed to render charts:", reason);
  });
};

const renderAll = async (elements: NodeListOf<Element>): Promise<void> => {
  // Dynamically load the chart library.
  const {
    Chart,
    BarController,
    BarElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
  } = await import("chart.js");

  // Register the components we use, so that they survive tree-shaking.
  Chart.register(
    BarController,
    BarElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
  );

  elements.forEach((el) => {
    const dataElement = el.querySelector("script");
    const canvas = el.querySelector("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");

    if (dataElement == null || ctx == null) {
      return;
    }

    const data = JSON.parse(dataElement.textContent ?? "");
    const datasets = data.datasets;
    const yUnit: string = " " + data.yUnit;

    return new Chart(ctx, {
      type: data.type,
      data: {
        labels: data.labels,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              color: "black",
            },
            border: {
              display: false,
            },
            stacked: true,
          },
          y: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              color: "black",
              callback: function (value: number) {
                return value.toLocaleString("en-US") + yUnit;
              },
            },
            border: {
              display: false,
            },
            stacked: true,
          },
        },
        plugins: {
          tooltip: {
            mode: "index",
            filter: function (tooltipItem: TooltipItem<any>) {
              return (tooltipItem.raw as number) > 0;
            },
            itemSort: function (a: TooltipItem<any>, b: TooltipItem<any>) {
              // Reverse the order to match the stacking order of the bars
              return b.datasetIndex - a.datasetIndex;
            },
            callbacks: {
              label: function (context: TooltipItem<any>) {
                let label = (context.dataset.label ?? "") as string;

                if (label !== "" && label !== null) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label +=
                    (context.parsed.y as number).toLocaleString("en-US") +
                    yUnit;
                }
                return label;
              },
              footer: function (tooltipItems: Array<TooltipItem<any>>) {
                let total = 0;
                tooltipItems.forEach(function (tooltipItem) {
                  total += tooltipItem.parsed.y as number;
                });
                return "Total: " + total.toLocaleString("en-US") + " " + yUnit;
              },
            },
          },
          legend: {
            display: data.showLegend,
            position: "right",
            labels: {
              usePointStyle: true,
              padding: 20,
              sort: function (a: TooltipItem<any>, b: TooltipItem<any>) {
                // Reverse the order to match the stacking order of the bars
                return b.datasetIndex - a.datasetIndex;
              },
            },
          },
        },
      },
    });
  });
};
