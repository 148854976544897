import { Controller } from "@hotwired/stimulus";

export default class NavbarController extends Controller {
  static values = { open: { type: Boolean, default: false } };
  declare openValue: boolean;

  static targets = ["content"];
  declare readonly contentTarget: HTMLElement;

  static classes = ["contentOpen"];
  declare readonly contentOpenClass: string;

  open(): void {
    this.openValue = true;
  }

  close(): void {
    this.openValue = false;
  }

  toggle(): void {
    this.openValue = !this.openValue;
  }

  openValueChanged(): void {
    this.contentTarget.classList.toggle(this.contentOpenClass, !this.openValue);
  }
}
