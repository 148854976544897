import { Controller } from "@hotwired/stimulus";

interface PubKeyResponseData {
  publicKey: string;
  publicKeyExpiryDate: string;
}

interface RevealResponseData {
  encryptedData: string;
  scaPassword: string;
}

export interface PaymentInstrumentInfo {
  pan: string;
  expiration: {
    year: string;
    month: string;
  };
  cvc: string;
}

export interface CardInfo extends PaymentInstrumentInfo {
  scaPassword: string;
}

export const revealCard = async (
  csrfToken: string,
  pubKeyUrl: string,
  revealUrl: string,
): Promise<CardInfo> => {
  const { RsaPubKey, AesKey, Data } = await import("../lib/adyen-crypto");

  // Get Adyen's current public key.
  const pubKeyResponse = await fetch(pubKeyUrl);
  const pubKeyResponseData: PubKeyResponseData = await pubKeyResponse.json();
  const pubKey = RsaPubKey.fromPem(pubKeyResponseData.publicKey);

  // Create a new secret key only available to the user's browser.
  const secretKey = AesKey.generate();

  // Encrypt the user's secret key with Adyen's public key so that it can safely
  // be passed through Cardboard's backend to Adyen.
  const encryptedSecretKey = pubKey.encrypt(secretKey);

  // Get the encrypted payment instrument info from Adyen.
  const revealResponse = await fetch(revealUrl, {
    method: "POST",
    headers: { "X-CSRFToken": csrfToken },
    body: JSON.stringify({ encryptedKey: encryptedSecretKey.asHex() }),
  });
  const revealResponseData: RevealResponseData = await revealResponse.json();

  // Decrypt the payment instrument info with the user's secret key.
  const data = secretKey.decrypt(
    Data.fromHex("00000000000000000000000000000000"),
    Data.fromHex(revealResponseData.encryptedData),
  );
  const paymentInstrumentInfo: PaymentInstrumentInfo = JSON.parse(data.asRaw());

  return {
    ...paymentInstrumentInfo,
    scaPassword: revealResponseData.scaPassword,
  };
};

export default class AdyenRevealController extends Controller {
  static values = {
    csrfToken: String,
    publicKeyUrl: String,
    revealUrl: String,
  };

  declare readonly csrfTokenValue: string;
  declare readonly publicKeyUrlValue: string;
  declare readonly revealUrlValue: string;

  static targets = ["pan", "expiration", "cvc", "scaPassword"];
  declare readonly panTarget: HTMLElement;
  declare readonly expirationTarget: HTMLElement;
  declare readonly cvcTarget: HTMLElement;
  declare readonly scaPasswordTarget: HTMLElement;

  async connect(): Promise<void> {
    const cardInfo = await revealCard(
      this.csrfTokenValue,
      this.publicKeyUrlValue,
      this.revealUrlValue,
    );
    this.panTarget.textContent = cardInfo.pan.replace(/(.{4})/g, "$1 ").trim();
    this.expirationTarget.textContent =
      cardInfo.expiration.month + "/" + cardInfo.expiration.year.slice(-2);
    this.cvcTarget.textContent = cardInfo.cvc;
    this.scaPasswordTarget.textContent = cardInfo.scaPassword;
  }
}
