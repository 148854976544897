type ConsentAction = "accept" | "reject";
type ConsentCategory = "functionality" | "performance" | "targeting" | "strict";
interface ConsentState {
  action: ConsentAction;
  categories: ConsentCategory[];
}
interface CookieScript {
  currentState: () => ConsentState;
  hide: () => void;
  show: () => void;
}

declare global {
  interface Window {
    CookieScript: { instance: CookieScript } | undefined;
  }
}

export function hasConsent(category: ConsentCategory): boolean {
  if (typeof window.CookieScript === "undefined") return false;
  const state = window.CookieScript.instance.currentState();
  return state.categories.includes(category);
}

export function showSettings(): boolean {
  if (typeof window.CookieScript === "undefined") return false;
  window.CookieScript.instance.show();
  return true;
}
