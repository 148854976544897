import { Controller } from "@hotwired/stimulus";

export default class DropdownController extends Controller {
  static targets = ["menu"];
  declare readonly menuTarget: HTMLElement;

  toggle(): void {
    this.menuTarget.classList.toggle("hidden");
  }

  hide(event: Event | null): void {
    if (event === null || !this.element.contains(event.target as Node)) {
      this.menuTarget.classList.add("hidden");
    }
  }
}
