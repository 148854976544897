import { Controller } from "@hotwired/stimulus";

export default class SidebarController extends Controller {
  static values = { open: { type: Boolean, default: false } };
  declare openValue: boolean;

  static targets = ["backdrop", "content"];
  declare readonly backdropTarget: HTMLElement;
  declare readonly contentTarget: HTMLElement;

  static classes = ["contentOpen", "backdropClose"];
  declare readonly contentOpenClass: string;

  open(): void {
    this.openValue = true;
  }

  close(): void {
    this.openValue = false;
  }

  toggle(): void {
    this.openValue = !this.openValue;
  }

  openValueChanged(): void {
    // Hide the backdrop when the sidebar is closed.
    this.backdropTarget.classList.toggle("hidden", !this.openValue);

    // Slide the sidebar in and out of view.
    this.contentTarget.classList.toggle(this.contentOpenClass, this.openValue);

    // Disable scrolling on the body when the sidebar is open.
    document.body.style.overflow = this.openValue ? "hidden" : "auto";
  }
}
