export const initTree = (root: Element): void => {
  const elements = root.querySelectorAll("canvas[data-pdf-url]");

  // If there are no elements, exit before loading the library.
  if (elements.length === 0) {
    return;
  }

  renderAll(elements).catch((reason) => {
    console.log("Failed to render PDFs:", reason);
  });
};

const renderAll = async (elements: NodeListOf<Element>): Promise<void> => {
  const pdfjs = (
    await Promise.all([
      import("pdfjs-dist"),
      import("pdfjs-dist/build/pdf.worker.min.mjs"),
    ])
  )[0];
  pdfjs.GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.mjs";

  for (const el of Array.from(elements)) {
    const canvas = el as HTMLCanvasElement;
    const pdfURL = canvas.getAttribute("data-pdf-url") ?? "";
    if (pdfURL === "") {
      console.error("PDF canvas needs to set the data-pdf-url attribute.");
      return;
    }
    const pdf = await pdfjs.getDocument(pdfURL).promise;

    const pageNumber = canvas.getAttribute("data-pdf-page") ?? "1";
    const page = await pdf.getPage(Number(pageNumber));
    const viewport = page.getViewport({ scale: 1 });

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const canvasContext = canvas.getContext("2d");
    if (canvasContext === null) {
      console.error("CanvasRenderingContext2D not available.");
      return;
    }

    const renderContext = { canvasContext, viewport };
    page.render(renderContext);
  }
};
