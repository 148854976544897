import { Controller } from "@hotwired/stimulus";

export default class DecisionMakersController extends Controller {
  static targets = ["formset"];
  declare readonly formsetTarget: HTMLElement;

  addDecisionMaker(event: Event | null): void {
    /* Un-hides the next decision maker form.

    All forms in the formset are hidden by default. When the user clicks the "Add" button,
    the next form is un-hidden.
    */

    if (event !== null) event.preventDefault();

    const nextHiddenForm = this.formsetTarget.querySelector(
      ".hidden.formset-form-instance",
    );
    if (nextHiddenForm === null) return;

    nextHiddenForm.classList.remove("hidden");

    // Un-check the "DELETE" checkbox, if it exists.
    const deleteCheckBox: HTMLInputElement | null =
      nextHiddenForm.querySelector('input[name$="DELETE"]');

    if (deleteCheckBox === null) return;
    deleteCheckBox.checked = false;
  }

  removeDecisionMaker(event: Event): void {
    /* Hide a decision maker form in the formset.

    When the user clicks the "Remove" button, the form is hidden and the "DELETE" checkbox
    is checked.
     */

    event.preventDefault();

    if (event.target === null) return;
    const form = (event.target as HTMLElement).closest(
      ".formset-form-instance",
    );

    if (form === null) return;

    form.classList.add("hidden");

    // Check the "DELETE" checkbox so that the decision maker is deleted on the next submit.
    const deleteCheckBox: HTMLInputElement | null = form.querySelector(
      'input[name$="DELETE"]',
    );
    if (deleteCheckBox === null) return;
    deleteCheckBox.checked = true;
  }
}
