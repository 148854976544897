/* eslint-disable @typescript-eslint/no-explicit-any */

import { extractEventPropsFromElement } from "./events";

declare global {
  interface Window {
    dataLayer: any[] | undefined;
  }
}

export const trackEvent = (event: string, props: any): void => {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({ event, ...props });
};

export const initTree = (root: Element): void => {
  // This tracks all clicks on elements with a data-event attribute.
  root.querySelectorAll("[data-event]").forEach((element) => {
    const event = element.getAttribute("data-event");
    if (event === null) return;
    element.addEventListener("click", () => {
      const props = extractEventPropsFromElement(element);
      trackEvent(event, props);
    });
  });
};
