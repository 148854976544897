import { Controller } from "@hotwired/stimulus";

export default class SlugifyController extends Controller {
  static targets = ["name", "slug"];
  declare readonly nameTarget: HTMLInputElement;
  declare readonly slugTarget: HTMLInputElement;

  generateSlug(): void {
    const nameValue = this.nameTarget.value;
    const slug = this.slugify(nameValue);
    this.slugTarget.value = slug;
  }

  slugify(text: string): string {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w-]+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
}
