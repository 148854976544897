import { Controller } from "@hotwired/stimulus";

import { showSettings } from "../cookies";

export default class CookieSettingsController extends Controller {
  show(): void {
    if (!showSettings()) {
      console.warn(
        "[cookie-settings#show]",
        "Cookie consent modal is not available.",
      );
    }
  }
}
