import { type ActionEvent, Controller } from "@hotwired/stimulus";

export default class AccordionController extends Controller {
  static values = { multipleOpen: { type: Boolean, default: false } };
  declare readonly multipleOpenValue: boolean;

  static targets = ["item", "icon"];
  declare readonly itemTargets: HTMLElement[];
  declare readonly iconTargets: HTMLElement[];

  toggle(event: ActionEvent): void {
    // TODO: This implementation might be nicer by splitting out a separate
    // controller for each accordion item.

    for (const [index, item] of this.itemTargets.entries()) {
      if (item.id === event.params.targetId) {
        // Toogle the target item
        item.classList.toggle("hidden");

        // Rotate the corresponding arrow
        this.iconTargets[index].classList.toggle(
          "rotate-180",
          !item.classList.contains("hidden"),
        );
      } else if (!this.multipleOpenValue) {
        // Hide all other items, unless multipleOpen is enabled
        item.classList.add("hidden");

        // Reset the rotation of all other icons
        this.iconTargets[index].classList.remove("rotate-180");
      }
    }
  }
}
