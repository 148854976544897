import { Controller } from "@hotwired/stimulus";

export default class TooltipController extends Controller {
  static values = { open: { type: Boolean, default: false } };
  declare openValue: boolean;

  static targets = ["bubble"];
  declare readonly bubbleTarget: HTMLElement;

  show(): void {
    this.openValue = true;
  }

  hide(): void {
    this.openValue = false;
  }

  openValueChanged(): void {
    this.bubbleTarget.classList.toggle("hidden", !this.openValue);
  }
}
