import "@github/auto-complete-element";
import type { AutoCompleteElement } from "@github/auto-complete-element";

const createAutocompleteChangeListener =
  (autoCompleteEl: AutoCompleteElement) => () => {
    const data: Record<string, string> = JSON.parse(autoCompleteEl.value);

    // Get the form that the auto-complete is in
    const form = autoCompleteEl.closest("form");
    if (form === null) return;

    // Update the matching input fields with the data from the auto-complete
    for (const [key, value] of Object.entries(data)) {
      const input: HTMLInputElement | null = form.querySelector(
        `[name="${key}"]`,
      );
      if (input === null) continue;
      input.value = value;
    }
  };

export const initTree = (root: Element): void => {
  root.querySelectorAll("auto-complete").forEach((el) => {
    el.removeEventListener(
      "auto-complete-change",
      createAutocompleteChangeListener(el),
    );
    el.addEventListener(
      "auto-complete-change",
      createAutocompleteChangeListener(el),
    );
  });
};
